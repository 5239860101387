import React from 'react';
import DesktopHeader from './header/desktop-header';
import MobileHeader from './header/mobile-header';


const Header = (props) =>{
    return(
        <>
            <MobileHeader/>
            <DesktopHeader/>
        </>
    );
}

export default Header;

