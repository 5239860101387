import cachios from 'cachios'; // GET


class Api {

    baseUrl = "https://bepensive.com/planetya5/wp-json/";

    getAbout = async () => {
      return await cachios.get(this.baseUrl + `wp/v2/pages?slug=about`);
    }

    getCollections = async () => {
      return await cachios.get(this.baseUrl + `wp/v2/collection`);
    }

    getSingleCollection = async (id) => {
      return await cachios.get(this.baseUrl + `wp/v2/collection/` + id);
    }

    getOptions = async () => {
      return await cachios.get(this.baseUrl + `acf/v3/options/options`);
    }
}

export default new Api();
