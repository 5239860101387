import React, {useState, useEffect}  from 'react';


const CollectionDesktop = ({data, title}) =>{

    //to create mark up data and set it dangerously
    function createMarkup(data){
        return {__html: (data || '')};
    }

    function arrangeImages (images) {
        let arr = [];
        let temp = [];
        for(let i in images){
            const {columns} = images[i];
            // 1 means 48% 2 means 100%
            if(columns === "2"){
                // 100% push alone to array
                arr.push([i]); // [[index// 0]]
            }else{
                temp.push(i); // temp [1,2]
                if(temp.length === 2) {
                    arr.push(temp);
                    temp = [];
                }
            }
        }

        // example of arr:  [ [0] , [1,2] ]
        return arr.map((row) => {
            // one column of 100%
            if (row.length === 1) {
                const imageIndex = row[0];
                return <div className="col-100">
                    <img src={images[imageIndex].image.url} alt="nft-img"/>
                </div>
            }
            return <div className="col-50">
                {row.map((col)=>{
                    return <img src={images[col].image.url} alt="nft-img"/>
                })}
            </div>

        })
    }

    return(
        <div className='collection-page-desktop'>
            <div className='collection-page-desktop-title'>
                <h1>{title}</h1>
                <a href={data?.subtitle_link?.url} className='blinking-text'>{data?.subtitle_link?.title}</a>
            </div>
            <div className='collection-page-desktop-content'>
                <div className='left-section'>
                    {
                        data.sections.map(({section_title, section_text}, i)=>{
                            return <div key={i} className={`${section_title}-section`}>
                                <p className='title'>{section_title}</p>
                                <p className='text' dangerouslySetInnerHTML={createMarkup(section_text)}/>
                            </div>
                        })
                    }
                </div>
                <div className='right-section'>
                    {arrangeImages(data.section_images)}
                </div>
            </div>
            {data.gallery_images? <>
                <div className='collection-page-desktop-gallery'>
                    {data.gallery_images.map(({image},i)=>{
                        return <img key={i} src={image.url} alt="gallery-img"/>
                    })}
                </div>
            </>
            :
            <></>}
            {data.store? <>
                <div className='collection-page-desktop-store'>
                <p className='title' >STORE</p>
                        <div className='store-container'>
                            {data.store.map((item)=>{
                                return <div className='store-content'>
                                    <img src={item.product_image.url} alt="nft-img"/>
                                    <p className='product-title'>{item.product_title}</p>
                                    <div className='product-contact-btn-container'>
                                        <a href={item.buy_nft_link} target="_blank" rel="noreferrer"><span>Buy NFT</span></a>
                                        <a href={item.token_link} target="_blank" rel="noreferrer"><span>Contract</span></a>
                                        <a href={item.buy_print_link} target="_blank" rel="noreferrer"><span>Buy Print</span></a>
                                    </div>
                                </div>
                            })}
                        </div>
                </div>
            </>
            :
            <></>}
            
        </div>
    );
}

export default CollectionDesktop;

