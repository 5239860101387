import React , {useState, useEffect} from 'react';
import Api from '../api/api';
// import { Link, useLocation } from 'react-router-dom';



const About = (props) =>{
   const [data, setData] = useState();
   //useEffect works just as component did mount and did update.
   useEffect(() => {
        // Update the document title using the browser API
        document.title = `Plant Ya5 / About`;
        function getData(){
            Api.getAbout().then((response)=>{
                setData(response.data[0].acf)
            })
        }
        getData();
    },[]);

    //to create mark up data and set it dangerously
    function createMarkup(data){
        return {__html: data};
    }


    return(
        <div className='about-page'>
        {data? 
            <>
                <h1>{data.main_title}</h1>
                <a href={data?.subtitle_link?.url} className='blinking-text'>{data?.subtitle_link?.title}</a>
                <div className='about-page-content'>
                    <p className='about-page-content-title' dangerouslySetInnerHTML={createMarkup(data.about_title)}/>
                    <p className='about-page-content-text' dangerouslySetInnerHTML={createMarkup(data.about_text)}/>
                </div>
            </> 
        : 
            <div className='empty-div'></div> 
        }
           
        </div>
    );
}

export default About;

