import React, {useState, useEffect} from 'react';
import { Link, useLocation } from 'react-router-dom';
import Api from '../../api/api';
import discord_icon from "./../../assets/img/discord.svg";
import instagram_icon from "./../../assets/img/instagram.svg";
import shopify_icon from "./../../assets/img/shopify.svg";
import twitter_icon from "./../../assets/img/twitter.svg";

const DesktopHeader = (props) => {

    const {pathname} = useLocation();
    const location = pathname.split("/").join("");
    const isAbout = location === "about"? "nav-underline" : "";
    const isCollection = location.indexOf("collection")>-1 ? "nav-underline" : "";
    const [data, setData] = useState();
    const [collectionData, setCollectionData] = useState();
    const [tabOpen, setTabOpen] = useState(false);
    const [selectedCollection, setSelectedCollection] = useState();

    // fetch the data from the api
    function getData(){
        Api.getOptions().then((response)=>{
            setData(response.data.acf)
        });
        Api.getCollections().then((response)=>{
            setCollectionData(response.data)
        });
    }


    useEffect(() => {
        getData();
    },[]);

    function scrollTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }

    function openCollectionsTab(){
        setTabOpen(!tabOpen);
        document.body.style.overflow = (!tabOpen)? "hidden" : "scroll";
    }

    const tabOpenClass= tabOpen? "open" : "";

    function collectionTabRender() {
        return(
            <div className='collections-tab'>
            {collectionData? 
                <div className={`collections-tab-content ${(collectionData.length < 6)? 'less-6' : ''}`}>
                    {(collectionData.length < 6)?
                        <div className='column-1'>{collectionData.map((collection, i)=>{
                            return <Link key={i} to={`collection/${collection.slug} `} onClick={()=>{setTabOpen(false); setSelectedCollection(collection.title.rendered)}} className={`collection-title ${(selectedCollection === collection.title.rendered)? "selected" : "" }`}>{collection.title.rendered}</Link>;
                        })}
                        </div>
                    :<>
                        {collectionData.map((collection, i)=>{
                            if(i%6 === 0){
                                const collectionDataCopy = [...collectionData];
                                return <div className={`column-${(i/6)+1}`}>
                                    {collectionDataCopy.splice(i, i+6).map((coll)=>{
                                        return <Link key={i} to={`collection/${collection.slug}`} onClick={()=>setTabOpen(false)} className='collection-title'>{coll.title.rendered}</Link>;
                                    })}
                                </div>;
                            }
                            return <></>
                        })}
                    </>}
                </div>
            :
                <div className='empty-div'></div>
            }
            </div>
        );
    }

    return(
        <div className='desktop-header'>
        {data? 
            <>
                <div className='desktop-header-left'>
                    <Link to={"/"}>
                        <span>PLANET<br/>YA5</span>
                    </Link>
                    <span className='circle' onClick={scrollTop}></span>
                </div>
                <div className='desktop-header-right'>
                    <div className='desktop-header-right-nav'>
                        <div className='collection-tab-container'>
                            <p className={`collections-nav ${tabOpenClass} ${isCollection}`} onClick={openCollectionsTab}>COLLECTIONS</p>
                            {tabOpen? <>{collectionTabRender()}</> : <></>}
                        </div>
                        <Link className={`about-nav ${isAbout} `} onClick={()=>{setTabOpen(false); setSelectedCollection(false)}} to="about">ABOUT</Link>
                        <div className='nav-contact-icons'>
                            <a href={data.instagram_link} rel="noreferrer" target="_blank" className='instagram-icon' ><img src={instagram_icon} alt="instagram" /></a>
                            <a href={data.twitter_link} rel="noreferrer" target="_blank" className='twitter-icon' ><img src={twitter_icon} alt="twitter" /></a>
                            <a href={data.discord_link} rel="noreferrer" target="_blank" className='discord-icon' ><img src={discord_icon} alt="discord" /></a>
                            <a href={data.shopify_link} rel="noreferrer" target="_blank" className='shopify-icon' ><img src={shopify_icon} alt="shopify" /></a>
                        </div>
                    </div>
                </div>
            </>
        :
            <></>
        }
            
        </div>
    );
}

export default DesktopHeader;
