import React, {useState,useEffect}  from 'react';


const CollectionMobile = ({data, title}) =>{

    const [tabData, setTabData] = useState("STORY");

    //to create mark up data and set it dangerously
    function createMarkup(data){
        return {__html: (data || '')};
    }

    useEffect(() => {
        setTabData("STORY");
    },[data]);  
    
    function renderContentText(data, tabData) {
        if(tabData === "STORE"){
            return <div className='store-container'>
                {data.store.map((item)=>{
                    return <div className='store-content'>
                        <img src={item.product_image.url} alt="nft-img"/>
                        <p className='product-title'>{item.product_title}</p>
                        <div className='product-contact-btn-container'>
                            <a href={item.buy_nft_link} target="_blank" rel="noreferrer"><span>Buy NFT</span></a>
                            <a href={item.token_link} target="_blank" rel="noreferrer"><span>Contract</span></a>
                            <a href={item.buy_print_link} target="_blank" rel="noreferrer"><span>Buy Print</span></a>
                        </div>
                    </div>
                })}
            </div>
        }else
        return data?.sections.map(({section_text,section_title},i)=>{
            if (section_title === tabData){
                return <p key={i} dangerouslySetInnerHTML={createMarkup(section_text)}/>;
            }
            return <></>;
        })
    }

    function arrangeImages (images) {
        let arr = [];
        let temp = [];
        for(let i in images){
            const {columns} = images[i];
            // 1 means 48% 2 means 100%
            if(columns === "2"){
                // 100% push alone to array
                arr.push([i]); // [[index// 0]]
            }else{
                temp.push(i); // temp [1,2]
                if(temp.length === 2) {
                    arr.push(temp);
                    temp = [];
                }
            }
        }

        // example of arr:  [ [0] , [1,2] ]
        return arr.map((row) => {
            // one column of 100%
            if (row.length === 1) {
                const imageIndex = row[0];
                return <div className="col-100">
                    <img src={images[imageIndex].image.url} alt="nft-img"/>
                </div>
            }
            return <div className="col-50">
                {row.map((col)=>{
                    return <img src={images[col].image.url} alt="nft-img"/>
                })}
            </div>

        })
    }

    return(
        <div className='collection-page-mobile'>
            <div className='collection-page-mobile-title'>
                <h1>{title}</h1>
                <a href={data?.subtitle_link?.url} className='blinking-text'>{data?.subtitle_link?.title}</a>
            </div>
            <div className='collection-page-mobile-content'>
                <div className='collection-page-mobile-content-nav'>
                    {data?.sections?.map((item, i)=>{
                        return <p className={`cursor-pointer ${(item.section_title === tabData)? 'selected' : ''}`} key={i} onClick={()=>setTabData(item.section_title)}>{item.section_title}</p>
                    })}
                    {
                        data.store? <>
                            <p className={`cursor-pointer ${("STORE" === tabData)? 'selected' : ''}`} onClick={()=>setTabData("STORE")}>STORE</p>
                        </>
                        :
                        <></>
                    }
                </div>
                <div className='collection-page-mobile-content-text'>
                    {renderContentText(data, tabData)}
                </div>
                <div className='collection-page-mobile-content-images'>
                    {arrangeImages(data.section_images)}
                </div>
                {data.gallery_images? <>
                <div className='collection-page-mobile-content-gallery'>
                    {data.gallery_images.map(({image},i)=>{
                        return <img key={i} src={image.url} alt="gallery-img"/>
                    })}
                </div>
                </>
                :
                <></>}
            </div>
        </div>
    );
}

export default CollectionMobile;

