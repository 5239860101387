import './App.scss';
import { BrowserRouter,Routes,Route} from "react-router-dom";
import Header from "./components/header.js";
import Home from './views/home';
import About from './views/about';
import Collection from './views/collection';
import Footer from './components/footer';

function App() {
  return (
    <BrowserRouter>

      <div className="App">
        <Header/>
        <Routes>
          <Route path="/" element={<Home />}/>
          <Route path="about" element={<About />}/>
          {/* used slug from api instead of id to have it in the url */}
          <Route path="collection/:collectionSlug" element={<Collection />} />
        </Routes>
        <Footer/>
      </div>

  </BrowserRouter>
  );
}

export default App;
