import React, {useState, useEffect}  from 'react';
import Api from '../api/api';
import { useParams } from "react-router-dom";
import CollectionDesktop from '../components/collection/collectionDesktop';
import CollectionMobile from '../components/collection/collectionMobile';



const Collection = (props) => {
    
    const [data, setData] = useState();
    const [title, setTitle] = useState();
    const {collectionSlug} = useParams();
    
    //fetch data
    function getData(collectionSlug){
        Api.getCollections().then((response)=>{
            const collections = response.data;
            const filteredData = collections.filter((r)=>{
                return r.slug === collectionSlug;
            })[0]
            setData(filteredData.acf)
            setTitle(filteredData.title.rendered)
        })
    }

    //call fetch functions on render and update.
    useEffect(() => {
        getData(collectionSlug);
    },[collectionSlug]);   

    // Update the document title using the browser API
    useEffect(() => {
        document.title = `Collection / ${title || ""}`;
        document.body.style.overflow = "scroll";
    },[title]);

    return(
        <div className='collection-page'>
        {data? 
            <>
                <CollectionDesktop data={data} title={title}/>
                <CollectionMobile data={data} title={title}/>
            </>
        :
            <div className='empty-div'></div>
        }
        </div>
    );
}

export default Collection;

