import React, {useState, useEffect}  from 'react';
import Api from '../api/api';
import discord_icon from "./../assets/img/discord.svg";
import instagram_icon from "./../assets/img/instagram.svg";
import shopify_icon from "./../assets/img/shopify.svg";
import twitter_icon from "./../assets/img/twitter.svg";

const Footer = (props) =>{
    const [data, setData] = useState();

    useEffect(() => {
        function getData(){
            Api.getOptions().then((response)=>{
                setData(response.data.acf)
            })
        }
        getData();
    },[]);

    return(
        <div className='footer'>
            {data? <>
                <div className='footer-content'>
                    <span className='footer-logo'>PLANET<br/>YA5</span>
                    <span className='copyrights'>© 2022 Planet Ya5. Privacy. Terms</span>
                    <div className='footer-nav-icons'>
                        <a href={data.instagram_link} target="_blank" rel="noreferrer" className='instagram-icon' ><img src={instagram_icon} alt="instagram" /></a>
                        <a href={data.twitter_link} target="_blank" rel="noreferrer" className='twitter-icon' ><img src={twitter_icon} alt="twitter" /></a>
                        <a href={data.discord_link} target="_blank" rel="noreferrer" className='discord-icon' ><img src={discord_icon} alt="discord" /></a>
                        <a href={data.shopify_link} target="_blank" rel="noreferrer" className='shopify-icon' ><img src={shopify_icon} alt="shopify" /></a>
                    </div>
                </div>
            </> 
            : 
            <></>}
            
        </div>
    );
}

export default Footer;

