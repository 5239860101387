import React,{useEffect} from 'react';
// import { withRouter, Link } from "react-router-dom";



const Home = (props) => {

    useEffect(() => {
        // Update the document title using the browser API
        document.title = `Planet Ya5`;
    },[]);

    return(
        <div className='home-page empty-div'>
        </div>
    );
}

export default Home;

