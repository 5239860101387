import React, {useState, useEffect}  from 'react';
import { Link, useLocation } from 'react-router-dom';
import { slide as Menu } from 'react-burger-menu';
import Api from '../../api/api';
import discord_icon from "./../../assets/img/discord.svg";
import instagram_icon from "./../../assets/img/instagram.svg";
import shopify_icon from "./../../assets/img/shopify.svg";
import twitter_icon from "./../../assets/img/twitter.svg";

const MobileHeader = (props) => {
    const [menuOpen, setMenuOpen] = useState(false);
    const {pathname} = useLocation();
    const location = pathname.split("/").join("");
    const isAbout = location === "about"? "nav-underline" : "";
    const [data, setData] = useState();
    const [collectionData, setCollectionData] = useState();
    const [tabOpen, setTabOpen] = useState(false);
    const [selectedCollection, setSelectedCollection] = useState();

    useEffect(() => {
        function getData(){
            Api.getOptions().then((response)=>{
                setData(response.data.acf)
            });
            Api.getCollections().then((response)=>{
                setCollectionData(response.data)
            });
        }
        getData();
    },[]);

    function handleMenuStateChange({isOpen}){
        setMenuOpen(isOpen);
    }

    function openCollectionsTab(){
        setTabOpen(!tabOpen);
    }

    function scrollTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }

    const tabOpenClass= tabOpen? "open" : "";


    function collectionTabRender() {
        return(
            <div className='collections-tab'>
            {data? 
                <div className='collections-tab-content'>
                    {collectionData.map((collection,i)=>{
                        return <Link key={i} to={`collection/${collection.slug}`} onClick={()=>{setMenuOpen(false); setSelectedCollection(collection.title.rendered)}} className={`collection-title ${(selectedCollection === collection.title.rendered)? "selected" : "" }`}>{collection.title.rendered}</Link>
                    })}
                </div>
            :
                <div className='empty-div'></div>
            }
            </div>
        );
    }

    return(
        <div className='mobile-header'>
            {data? 
                <>
                <div className='mobile-header-left'>
                    <Link to={"/"}>
                        <span>PLANET<br/>YA5</span>
                    </Link>
                    <span className='circle' onClick={scrollTop}></span>
                </div>
                <div className='mobile-header-right'>
                    <Menu
                        isOpen={menuOpen}
                        onStateChange={(state) => handleMenuStateChange(state)}
                        right
                        disableAutoFocus
                    >
                        <div className="bm-links">
                            <div className='collection-tab-container'>
                                <p className={`collections-nav ${tabOpenClass}`} onClick={openCollectionsTab}>COLLECTIONS</p>
                                {tabOpen? <>{collectionTabRender()}</> : <></>}
                            </div>
                            <Link to="/about" className={`about-nav ${isAbout} `} onClick={()=>{setMenuOpen(false); setSelectedCollection(''); openCollectionsTab(false)}} >ABOUT</Link>
                            <div className='nav-contact-icons'>
                                <a href={data.instagram_link} target="_blank" rel="noreferrer" className='instagram-icon' ><img src={instagram_icon} alt="instagram" /></a>
                                <a href={data.twitter_link} target="_blank" rel="noreferrer" className='twitter-icon' ><img src={twitter_icon} alt="twitter" /></a>
                                <a href={data.discord_link} target="_blank" rel="noreferrer" className='discord-icon' ><img src={discord_icon} alt="discord" /></a>
                                <a href={data.shopify_link} target="_blank" rel="noreferrer" className='shopify-icon' ><img src={shopify_icon} alt="shopify" /></a>
                            </div>
                        </div>
                    </Menu>
                </div>
                </>
            :
                <></>
            }
        </div>
    );
}

export default MobileHeader;
